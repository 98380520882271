<template>
  <div class="consent">
    <MDBContainer>
      <MDBRow center class="py-5">
        <MDBCol md="10" class="text-start">
          <h1>Informed Consent to Participate in Research</h1>

          <p> Footprints Behavioral Intervention is inviting you to participate in a research study. The purpose of
            this research is to gain a better understanding about how online training improves participants’ professional
            development performance (i.e., medical documentation).
          </p>
          <h2>Procedures</h2>
          <p> Participants are asked to complete their regular, daily job requirements as related to their position at
            Footprints Behavioral Intervention.  This includes completing required professional development trainings
            and medical documentation following services. Participants are asked to participate in data collection
            procedures associated with the study including pre-post assessments and fidelity scores.
          </p>
          <h2>Risks</h2>
          <p>There are no more than minimal risks (what one would encounter in daily life) associated with this study.</p>
          <h2>Benefits</h2>
          <p>Participants may directly improve their performance on completing medical documentation after participating
            in this study. There may be indirect benefits related to the researcher’s understanding about the research
            topic.</p>
          <h2>Voluntary Participation</h2>
          <p>Participation to use your data in this research study’s report is voluntary. You have the right to refuse or
            withdraw your data from the study at any time without penalty.</p>
          <h2>Confidentiality</h2>
          <p>All data collected for this study and/or any identifying records will remain confidential.  Any personal
            information that could identify you will be coded with a number. The principal investigator will keep a link
            that identifies you to your coded information, but this link will be kept secure and available only to the
            principal investigator or selected members of the research team. Furthermore, information that could
            identify you will be removed or changed before files are shared with other researchers or results are
            made public in professional journals and meetings.</p>
          <h2>Debriefing</h2>
          <p>You have the right to have all questions about the study answered in sufficient detail for you to clearly
            understand the level of your participation as well as the significance of the research. At the completion
            of the study, you will have an opportunity to ask and have all questions answered pertaining to your
            involvement in this study by contacting Shannon Thomas at <a href="mailto:sthomas@autismfootprints.com">sthomas@autismfootprints.com</a>.</p>
          <h2>Receipt of informed consent</h2>
          <p>I acknowledge that I may call the primary investigator involved in the study in order to discuss
            confidentially and any questions about my participation in the study.</p>
          <h2>Acknowledgement of Consent</h2>
          <p>By participating in this study, I provide my consent and I acknowledge that I am 18 years of age or older.</p>
          <h2>Contact Information</h2>
          <p>Primary Investigator:<br>
            Shannon Thomas, Ph.D., BCBA-D<br>
            <a href="mailto:sthomas@autismfootprints.com">sthomas@autismfootprints.com</a>
          </p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </div>
</template>

<script>
import {
  MDBContainer, MDBRow, MDBCol
} from "mdb-vue-ui-kit";

export default {
  name: "ConsentInfo",
  components: {
    MDBContainer, MDBRow, MDBCol
  }
}
</script>

<style scoped>

</style>