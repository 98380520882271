<template>
  <div class="admin">
    <MDBContainer class="pt-5">
      <div v-for="(trainee, i) in groupedData" :key="i" class="pt-3">
        <h2> {{ i.charAt(0).toUpperCase() + i.substr(1).toLowerCase() }}</h2>
        <div>
          <MDBRow>
            <MDBCol><p> Training 1</p></MDBCol>
            <MDBCol>{{ trainee.training1 || 0 }}</MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol><p> Training 2</p></MDBCol>
            <MDBCol>{{ trainee.training2 || 0 }}</MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol><p> Training 3 Video 1</p></MDBCol>
            <MDBCol>{{ trainee['training3-1'] || 0 }}</MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol><p> Training 3 Video 2</p></MDBCol>
            <MDBCol>{{ trainee['training3-2'] || 0 }}</MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol><p> Training 4</p></MDBCol>
            <MDBCol>{{ trainee.training4 || 0 }}</MDBCol>
          </MDBRow>

        </div>
      </div>
    </MDBContainer>
  </div>
</template>

<script>
import {
    MDBContainer,
    MDBRow,
    MDBCol
} from "mdb-vue-ui-kit";
import { db, collection, getDocs } from '@/firebase/init'

export default {
  name: "FootprintsAdmin",
  components: {
    MDBContainer,
    MDBRow,
    MDBCol
  },
  data(){
    return {
      data: [],
      groupedData: []
    }
  },
  async created(){
    const querySnapshot = await getDocs(collection(db, 'trainees'))
    querySnapshot.forEach((doc) => {
      this.data.push(doc.data())
    })
    this.groupedData = this.groupedTrainees()
  },
  methods: {
    groupedTrainees(){
      let groupBy = function(xs, key, keys, red = (acc, curr, ) => ([...acc, curr]), init = []) {
        return xs.reduce(function(storage, curr) {
          const acc = storage[curr[key].toLowerCase()] || init;
          console.log(acc)
          return { ...storage, [curr[key].toLowerCase()]: {
              [keys[0]]: red(((storage[curr[key].toLowerCase()]) ?? 0)[keys[0]] ?? 0, curr, keys[0]),
              [keys[1]]: red(((storage[curr[key].toLowerCase()]) ?? 0)[keys[1]] ?? 0, curr, keys[1]),
              [keys[2]]: red(((storage[curr[key].toLowerCase()]) ?? 0)[keys[2]] ?? 0, curr, keys[2]),
              [keys[3]]: red(((storage[curr[key].toLowerCase()]) ?? 0)[keys[3]] ?? 0, curr, keys[3]),
              [keys[4]]: red(((storage[curr[key].toLowerCase()]) ?? 0)[keys[4]] ?? 0, curr, keys[4]),
            }
          };
        }, {});
      };
      let sumOfAmounts = (previousTotal, product, key) => previousTotal + (product[key] || 0)
      return groupBy(
          this.data,
          'name',
          ['training1', 'training2', 'training3-1', 'training3-2', 'training4'],
          sumOfAmounts,
          0)
    }
  }
}
</script>

<style scoped>

</style>