// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, updateDoc, getDocs } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCi0RPjTImovP90bJ5tUbkZCn9eQ-1QVEk",
    authDomain: "footprints-training.firebaseapp.com",
    projectId: "footprints-training",
    storageBucket: "footprints-training.appspot.com",
    messagingSenderId: "574708692860",
    appId: "1:574708692860:web:260a031d241b55d53296f9",
    measurementId: "G-YG1ENTMP35"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

export { db, collection, addDoc, updateDoc, getDocs }
